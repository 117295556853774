<template>
  <div class="col action" :title="action" :style="'background:'+color+';'"> 
    <svg v-if="action == 'action'" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""> 
      <polygon  points="412.351,143.937 445.567,110.721 401.279,66.432 368.063,99.649 268.415,0 135.552,132.865 379.135,376.448     512,243.585   " fill="#ffffff" data-original="#000000" style="" class="" />
      <path d="M201.983,243.585L13.759,431.81c-18.345,18.345-18.345,48.088,0,66.432c18.345,18.345,48.088,18.345,66.432,0    l188.224-188.224L201.983,243.585z"  fill="#ffffff" data-original="#000000" style="" class="" />
    </svg>
    <svg v-if="action == 'post'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
    <svg v-if="action == 'send'" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 4.01237C33.8493 3.99588 33.6974 3.99588 33.5466 4.01237H2.58604C2.38761 4.01561 2.19049 4.0472 2 4.10629L17.9779 21L34 4.01237Z" fill="white"/>
      <path d="M35.865 6L19.485 22.674C19.0634 23.1024 18.4932 23.3428 17.8987 23.3428C17.3043 23.3428 16.7341 23.1024 16.3125 22.674L0.0787501 6.12649C0.0288444 6.31398 0.00238352 6.50717 0 6.70146V29.7001C0 30.3101 0.237053 30.8951 0.65901 31.3264C1.08097 31.7577 1.65326 32 2.25 32H33.75C34.3467 32 34.919 31.7577 35.341 31.3264C35.7629 30.8951 36 30.3101 36 29.7001V6.70146C35.991 6.46188 35.9455 6.2252 35.865 6V6ZM3.79125 29.7001H2.2275V28.0557L10.4062 19.7647L11.9925 21.3861L3.79125 29.7001ZM33.7275 29.7001H32.1525L23.9512 21.3861L25.5375 19.7647L33.7162 28.0557L33.7275 29.7001Z" fill="white"/>
    </svg>
    <svg v-if="action == 'archive'" width="20" height="20" viewBox="0 0 20 19" fill="none" >
      <path d="M19.704 3.29L16.707 0.293001C16.6143 0.199958 16.5041 0.126171 16.3828 0.0758854C16.2614 0.0256001 16.1313 -0.000189449 16 1.04767e-06H4C3.86866 -0.000189449 3.73857 0.0256001 3.61724 0.0758854C3.4959 0.126171 3.38571 0.199958 3.293 0.293001L0.296001 3.29C0.202087 3.38263 0.127549 3.49302 0.0767297 3.61475C0.0259107 3.73647 -0.00017223 3.86709 8.55824e-07 3.999V17C8.55824e-07 17.5304 0.210714 18.0391 0.585787 18.4142C0.96086 18.7893 1.46957 19 2 19H18C18.5304 19 19.0391 18.7893 19.4142 18.4142C19.7893 18.0391 20 17.5304 20 17V3.999C20.0002 3.86709 19.9741 3.73647 19.9233 3.61475C19.8725 3.49302 19.7979 3.38263 19.704 3.29V3.29ZM4.414 2H15.586L16.586 3H3.414L4.414 2ZM15 11V12H5V8H7V10H13V8H15V11Z" fill="white"/>
    </svg>
    <svg v-if="action == 'user'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 7.51678C3 10.0913 4.806 12.0336 7.2 12.0336C9.594 12.0336 11.4 10.0913 11.4 7.51678C11.4 4.94221 9.594 3 7.2 3C4.806 3 3 4.94221 3 7.51678ZM20.4 6.80442H18V10.6759H14.4V13.257H18V17.1285H20.4V13.257H24V10.6759H20.4V6.80442ZM2.4 21H14.4V19.7095C14.4 16.1516 11.7084 13.257 8.4 13.257H6C2.6916 13.257 0 16.1516 0 19.7095V21H2.4Z" fill="white"/>
    </svg>
    <svg v-if="action == 'clone'" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 6V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H6V8C6 7.46957 6.21071 6.96086 6.58579 6.58579C6.96086 6.21071 7.46957 6 8 6H20Z" fill="white"/>
      <path d="M26 8H10C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10V26C8 26.5304 8.21071 27.0391 8.58579 27.4142C8.96086 27.7893 9.46957 28 10 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V10C28 9.46957 27.7893 8.96086 27.4142 8.58579C27.0391 8.21071 26.5304 8 26 8ZM24 19H19V24H17V19H12V17H17V12H19V17H24V19Z" fill="white"/>
    </svg>
    <svg v-if="action == 'favorite'" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.41598 22.4152C4.83698 22.7122 4.17998 22.1917 4.29698 21.5272L5.54198 14.4323L0.25748 9.39825C-0.23602 8.92725 0.0204801 8.06625 0.68198 7.97325L8.02898 6.92925L11.305 0.43875C11.6005 -0.14625 12.4 -0.14625 12.6955 0.43875L15.9715 6.92925L23.3185 7.97325C23.98 8.06625 24.2365 8.92725 23.7415 9.39825L18.4585 14.4323L19.7035 21.5272C19.8205 22.1917 19.1635 22.7122 18.5845 22.4152L11.998 19.0312L5.41448 22.4152H5.41598Z" fill="black"/>
    </svg>
    <svg v-if="action == 'clipboard'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="#fff" fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"/></svg>
    <svg v-if="action == 'edit'" width="105" height="100" viewBox="0 0 105 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.78445 100C4.98509 99.9986 4.19477 99.8389 3.46373 99.5308C2.7327 99.2228 2.07695 98.7732 1.53818 98.2106C0.989677 97.6529 0.571128 96.991 0.309987 96.2683C0.0488455 95.5456 -0.0489889 94.7785 0.022897 94.0173L1.43448 79.2308L66.6437 17.1318L87.0223 36.5398L21.8304 98.6333L6.30875 99.978C6.13445 99.9933 5.95948 100.001 5.78445 100V100ZM91.09 32.6593L70.7171 13.2513L82.9374 1.60976C83.4725 1.09944 84.1079 0.6946 84.8073 0.418386C85.5068 0.142172 86.2565 0 87.0137 0C87.7708 0 88.5205 0.142172 89.22 0.418386C89.9194 0.6946 90.5549 1.09944 91.09 1.60976L103.31 13.2513C103.846 13.761 104.271 14.3664 104.561 15.0327C104.851 15.699 105 16.4132 105 17.1345C105 17.8558 104.851 18.57 104.561 19.2363C104.271 19.9027 103.846 20.508 103.31 21.0178L91.0957 32.6538L91.09 32.6593V32.6593Z" fill="white" />
    </svg>
    <svg v-if="action == 'refresh'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1536"><path fill="white" d="M1511 928q0 5-1 7q-64 268-268 434.5T764 1536q-146 0-282.5-55T238 1324l-129 129q-19 19-45 19t-45-19t-19-45V960q0-26 19-45t45-19h448q26 0 45 19t19 45t-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117q8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45t19-45l138-138Q969 256 768 256q-134 0-250 65T332 500q-11 17-53 117q-8 23-30 23H50q-13 0-22.5-9.5T18 608v-7q65-268 270-434.5T768 0q146 0 284 55.5T1297 212l130-129q19-19 45-19t45 19t19 45z"/></svg>
    <svg v-if="action == 'sms'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M8 11q.425 0 .713-.288Q9 10.425 9 10t-.287-.713Q8.425 9 8 9t-.713.287Q7 9.575 7 10t.287.712Q7.575 11 8 11Zm4 0q.425 0 .713-.288Q13 10.425 13 10t-.287-.713Q12.425 9 12 9t-.712.287Q11 9.575 11 10t.288.712Q11.575 11 12 11Zm4 0q.425 0 .712-.288Q17 10.425 17 10t-.288-.713Q16.425 9 16 9t-.712.287Q15 9.575 15 10t.288.712Q15.575 11 16 11ZM2 22V4q0-.825.588-1.413Q3.175 2 4 2h16q.825 0 1.413.587Q22 3.175 22 4v12q0 .825-.587 1.413Q20.825 18 20 18H6Z"/></svg>
    <svg v-if="action == 'docs'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="white" d="M17 2v7a3 3 0 0 0 3 3h7v15a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h9Zm2 .117V9a1 1 0 0 0 1 1h6.883a3 3 0 0 0-.762-1.293L20.293 2.88A3 3 0 0 0 19 2.117Z"/></svg>
    <svg v-if="action == 'desarchive'" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="m21.706 5.292l-2.999-2.999A.996.996 0 0 0 18 2H6a.996.996 0 0 0-.707.293L2.294 5.292A.994.994 0 0 0 2 6v13c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6a.994.994 0 0 0-.294-.708zM6.414 4h11.172l1 1H5.414l1-1zM14 14v3h-4v-3H7l5-5l5 5h-3z"/></svg>
    <svg v-if="action == 'checkout'" width="30" height="30" viewBox="0 0 24 24" fill="none" >
      <path d="M24 7.5V18.75C24 19.9875 23.0182 21 21.8182 21H4.36364C3.76364 21 3.27273 20.4938 3.27273 19.875C3.27273 19.2562 3.76364 18.75 4.36364 18.75H21.8182V7.5C21.8182 6.88125 22.3091 6.375 22.9091 6.375C23.5091 6.375 24 6.88125 24 7.5ZM3.27273 16.5C1.46182 16.5 0 14.9925 0 13.125V6.375C0 4.5075 1.46182 3 3.27273 3H16.3636C18.1745 3 19.6364 4.5075 19.6364 6.375V14.25C19.6364 15.4875 18.6545 16.5 17.4545 16.5H3.27273ZM6.54545 9.75C6.54545 11.6175 8.00727 13.125 9.81818 13.125C11.6291 13.125 13.0909 11.6175 13.0909 9.75C13.0909 7.8825 11.6291 6.375 9.81818 6.375C8.00727 6.375 6.54545 7.8825 6.54545 9.75Z" fill="white"/>
    </svg>
    <svg v-if="action == 'email'" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g >
        <path d="M94.9968 13.0325C94.5709 12.9892 94.1415 12.9892 93.7156 13.0325H6.21558C5.65478 13.041 5.09769 13.1239 4.55933 13.279L49.7156 57.6181L94.9968 13.0325Z" fill="white"/>
        <path d="M99.625 17.333L54.125 62.0111C52.954 63.1588 51.3699 63.8031 49.7187 63.8031C48.0676 63.8031 46.4835 63.1588 45.3125 62.0111L0.21875 17.6719C0.0801232 18.1743 0.00662089 18.692 0 19.2126V80.8375C0 82.4719 0.65848 84.0393 1.83058 85.195C3.00269 86.3507 4.5924 87 6.25 87H93.75C95.4076 87 96.9973 86.3507 98.1694 85.195C99.3415 84.0393 100 82.4719 100 80.8375V19.2126C99.9751 18.5706 99.8486 17.9364 99.625 17.333V17.333ZM10.5313 80.8375H6.1875V76.4313L28.9062 54.2155L33.3125 58.5601L10.5313 80.8375ZM93.6875 80.8375H89.3125L66.5312 58.5601L70.9375 54.2155L93.6562 76.4313L93.6875 80.8375Z" fill="white"/>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"  v-if="action == 'dash'" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"/></svg>
    <svg v-if="action == 'view'"  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M396 512a112 112 0 1 0 224 0a112 112 0 1 0-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3c7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176s-78.8 176-176 176z"/></svg>
    <svg v-if="action == 'delete'" width="78" height="100" viewBox="0 0 78 100" fill="none" >
      <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
    </svg>
    <svg v-if="action == 'new'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 6H8V0H6V6H0V8H6V14H8V8H14V6Z" fill="black"/>
    </svg>
    <svg v-if="action == 'filter'" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="#5a5a5a" d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"/></svg>
    <svg v-if="action == 'check'" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1347 1.31882C10.3421 1.11207 10.618 0.997764 10.9043 1.00003C11.1906 1.0023 11.4649 1.12096 11.6692 1.33098C11.8736 1.541 11.992 1.82594 11.9996 2.12569C12.0072 2.42543 11.9033 2.71653 11.7098 2.93756L5.83628 10.6305C5.73529 10.7444 5.61339 10.8358 5.47788 10.8993C5.34238 10.9627 5.19604 10.9969 5.04763 10.9998C4.89922 11.0027 4.75177 10.9742 4.61412 10.916C4.47646 10.8579 4.35141 10.7712 4.24646 10.6613L0.351393 6.58205C0.242921 6.4762 0.15592 6.34855 0.095577 6.20672C0.0352345 6.06489 0.00278735 5.91178 0.000171818 5.75653C-0.00244371 5.60128 0.024826 5.44707 0.0803535 5.30309C0.135881 5.15912 0.218529 5.02834 0.323367 4.91854C0.428205 4.80875 0.553086 4.72219 0.690558 4.66404C0.828031 4.60588 0.97528 4.57732 1.12352 4.58006C1.27176 4.5828 1.41796 4.61678 1.55338 4.67998C1.68881 4.74318 1.8107 4.83429 1.91177 4.94789L4.99426 8.17459L10.1067 1.35274C10.1159 1.34087 10.1258 1.32954 10.1362 1.31882H10.1347Z" fill="black"/>
    </svg>
    <slot>

    </slot>
  </div>
</template>
<script>
import { defaults } from '@apimatic/schema';
import Vuex from "vuex";
export default {
  props:{
    action:{
      type: String,
      default: 'none'
    },
  },
  data() {
    return {
      color:'#ccc',
      let:'',
    }
  },
  created() {
    this.color = this.organizationPalette[1]
    return
    try {
      switch (this.action) {
        case 'delete': this.color = '#fc182b'; break;
        case 'view': this.color = '#0075FA'; break;
  
        case 'action':  this.color = this.organizationData.colorSeriesA[1]; break;
        case 'dash':  this.color = this.organizationData.colorSeriesA[4]; break;
        case 'send': this.color = this.organizationData.colorSeriesA[2]; break;
        case 'archive': this.color = this.organizationData.colorSeriesA[3]; break;
        case 'desarchive': this.color = this.organizationData.colorSeriesA[3]; break;
        case 'favorite': this.color = this.organizationData.colorSeriesA[4]; break;
        case 'clone': this.color = this.organizationData.colorSeriesA[5]; break;
        case 'checkout': this.color = this.organizationData.colorSeriesA[6]; break;
        case 'clipboard': this.color = this.organizationData.colorSeriesA[2]; break;
        case 'email': this.color = this.organizationData.colorSeriesA[3]; break;
        case 'user': this.color = this.organizationData.colorSeriesA[4]; break;
        case 'post': this.color = this.organizationData.colorSeriesA[5]; break;
        case 'edit': this.color = this.organizationData.colorSeriesA[0]; break;
        case 'refresh': this.color = this.organizationData.colorSeriesA[2]; break;
        case 'sms': this.color = this.organizationData.colorSeriesA[3]; break;
        case 'docs': this.color = this.organizationData.colorSeriesA[4]; break;
        case 'new': this.color = this.organizationData.colorSeriesA[0]; break;
        case 'filter': this.color = this.organizationData.colorSeriesA[1]; break;
        case 'check': this.color = this.organizationData.colorSeriesA[2]; break;
        default: this.color = this.organizationData.colorSeriesA[0]; break;
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...Vuex.mapState(["organizationData","organizationPalette"]),
  },
}
</script>
