<template>
  <draggable
    :style="'width: 100%; border: 1px dashed rgb(204, 204, 204); box-sizing: border-box;'+styleDragg"
    v-bind="dragOptions"
    class="dragArea smart-wrap"
    :list="listDrop"
    group="components"
    @change="log"
    item-key="id" 
  > 
    <template #item="{ element }">
      <div :class="element.metaType.replace('meta','').toLowerCase()+'-wrap repeatable-wrap '+ element.class" :style="setStyle(element, 'wrap')"  data-toggle="modal" :data-target="'#modal-custom'" v-on:click="setCustomItem(element);">
        <h1 v-if="element.metaType == 'Tag'|| element.metaType == 'paymentMethod' ">{{element.attributeName}}</h1>
        <div class="calls" v-if="editable == true" >
          <p>{{mode[index]}} </p>
          <label class="switch" style="float:left;">
            <input type="checkbox" v-model="edit" />
            <span class="slider round"></span>
          </label>
        </div>
        <!-- LABEL/HELP -->
        <div class="label" v-if="element.metaType != 'metaModule' && element.metaType != 'customModule' && element.metaType != 'metaElement' && element.metaType != 'Tag' && element.metaType != 'paymentMethod'">
          <label :style="element.styleLabel" >{{element.label}}</label>
          
          <!--Help-->
          <div v-if="element.help != '' && element.help != undefined && element.help != ' ' && element.help != 'undefined'" class="dropdown show labelOptions">
            <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg  v-on:click="help(element)"  width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
              </svg>
            </a>
            <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
              <span :id="'help'+element.id"> {{ element.help }}</span>
            </div>
          </div>
          <!--Clip-->
          <div v-if=" element.smartInput == true && !element.usersCanEdit.includes('@client') != true" class="dropdown show labelOptions">
            <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor:pointer;">
              <svg  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="forestgreen" fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"/></svg>
            </a>
            
          </div>
        </div> 
        <p style="font-size: 12px;" >{{element.description}}</p>
        <!--Coments-->
        <div v-if="element.metaType != 'metaModule' && element.metaType != 'metaElement' && element.metaType != 'Tag' && element.smartInput == true && !element.usersCanEdit.includes('@client') != true" class="dropdown show labelOptions">
          <a style="color:#fff; font-size: 14px; font-weight: 400; background:#0049c9; padding:3px 3px 0px;; border-radius:5px; cursor:pointer;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="white" d="M924.3 338.4a447.57 447.57 0 0 0-96.1-143.3a443.09 443.09 0 0 0-143-96.3A443.91 443.91 0 0 0 512 64h-2c-60.5.3-119 12.3-174.1 35.9a444.08 444.08 0 0 0-141.7 96.5a445 445 0 0 0-95 142.8A449.89 449.89 0 0 0 65 514.1c.3 69.4 16.9 138.3 47.9 199.9v152c0 25.4 20.6 46 45.9 46h151.8a447.72 447.72 0 0 0 199.5 48h2.1c59.8 0 117.7-11.6 172.3-34.3A443.2 443.2 0 0 0 827 830.5c41.2-40.9 73.6-88.7 96.3-142c23.5-55.2 35.5-113.9 35.8-174.5c.2-60.9-11.6-120-34.8-175.6zM312.4 560c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.4 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.5 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48s47.9 21.5 47.9 48s-21.5 48-47.9 48z"/></svg>
          </a>
          
        </div>
        <p v-if="element.metaType != 'metaModule' && element.metaType != 'metaElement' && element.metaType != 'Tag' && element.smartInput == true && !element.usersCanEdit.includes('@client') != true " style="color:#F23D3D; font-size: 12px; font-weight: 600;">
          <i class='bx bxs-bell-ring' style='color:#ff0000'  ></i> 
          <span style="font-weight: 700;">Specialists Comments: </span> 
        </p>
        <!-- Meta Input -->
        <div v-if="element.metaType == 'metaInput' && element.isRepeatable == false" >
          <!-- Text -->
          <input :style="element.styleInput" v-if=" !element.inputType || element.inputType == 'Text' || element.inputType == 'Number' || element.inputType == 'Date' || element.inputType == 'Currency' || element.inputType == 'Email' || element.inputType == 'Phone' || element.inputType == 'createdBy'"  disabled :type="element.inputType.toLowerCase()" v-model="element.attributeName" />
          <!-- SELECT -->
          <select  :style="element.styleInput" disabled v-if="element.inputType == 'Select' || element.inputType == 'GRAPHQL' || element.inputType == 'preloadList' || element.inputType == 'GRAPHQLPLUS'" v-model="element.attributeName"> <option :value="element.attributeName">{{element.attributeName}}</option></select>
          <!-- Quote -->
          <div v-if="element.inputType == 'quote'" class="row">
            <div class="col-md-8 row">
              <input type="text" class="col-12" v-model="element.attributeName">
              <br> <br>
              <span class="col-6" style="color: #787878; font-size: 14px; font-weight: 600;" >Subtotal:</span> <span class="col-6" style="text-align:right; " >$ 0.00</span>
              <span class="col-6" style="color: #787878; font-size: 14px; font-weight: 600;" >Descuentos:</span> <span class="col-6" style="text-align:right; " >$ 0.00</span>
              <span class="col-6" style="color: #787878; font-size: 14px; font-weight: 600;" >Total:</span> <span class="col-6" style="text-align:right; " >$ 0.00</span>
              <div class="element-wrap">
                <table class="table table-repeatable" id="myTable" style="min-width: 100%" >  
                  <thead>
                    <th>No.</th>
                    <th>Options</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>...</td>
                      <td class="options-td" style="padding: 0px !important; ">
                        <div class="options" style="width: auto;!important">
                          <a style="cursor: pointer" > 
                            <div class="col edit" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                              <svg width="105" height="100" viewBox="0 0 105 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.78445 100C4.98509 99.9986 4.19477 99.8389 3.46373 99.5308C2.7327 99.2228 2.07695 98.7732 1.53818 98.2106C0.989677 97.6529 0.571128 96.991 0.309987 96.2683C0.0488455 95.5456 -0.0489889 94.7785 0.022897 94.0173L1.43448 79.2308L66.6437 17.1318L87.0223 36.5398L21.8304 98.6333L6.30875 99.978C6.13445 99.9933 5.95948 100.001 5.78445 100V100ZM91.09 32.6593L70.7171 13.2513L82.9374 1.60976C83.4725 1.09944 84.1079 0.6946 84.8073 0.418386C85.5068 0.142172 86.2565 0 87.0137 0C87.7708 0 88.5205 0.142172 89.22 0.418386C89.9194 0.6946 90.5549 1.09944 91.09 1.60976L103.31 13.2513C103.846 13.761 104.271 14.3664 104.561 15.0327C104.851 15.699 105 16.4132 105 17.1345C105 17.8558 104.851 18.57 104.561 19.2363C104.271 19.9027 103.846 20.508 103.31 21.0178L91.0957 32.6538L91.09 32.6593V32.6593Z" fill="white" />
                              </svg>
                            </div>
                          </a>
                          <a style="cursor: pointer" >
                            <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                              <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                                <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> 
            <div class="col-md-4" style="padding:0 10px;">
              <div class="card">
                <div class="card-body">       
                  List...
                </div>
              </div>
            </div>

          </div>
          <!-- Calendar -->
          <div v-if="element.inputType == 'calendar'" class="row">
            <div class="col-md-8 row">
              <table id="calendar" class="preview-calendar" >
                <thead>
                    <tr>
                      <th>S</th>
                      <th>M</th>
                      <th>T</th>
                      <th>W</th>
                      <th>T</th>
                      <th>F</th>
                      <th>S</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(week, windex) in 6" :key="windex" > 
                      <td v-for="(day, dindex) in 7" :key="dindex" id="days">
                          <a>{{day}}</a>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div> 
            <div class="col-md-4" style="padding:0 10px;">
              <div class="card">
                <div class="card-body">       
                  List...
                </div>
              </div>
            </div>

          </div>
          <!-- Checks -->
          <div  v-if=" element.inputType == 'CheckBox'">
            <div class="envelop" v-for="(check, zindex) in element.valueOptions" :key="zindex">
              <input type="checkbox" class="box" :value="check" v-model="element.attributeName"/>
              <span class="text" > {{check}} </span>
            </div>
          </div>
          <!-- Range -->
          <br v-if="element.inputType == 'Range'">
          <span  v-if="element.inputType == 'Range'"> Value: -- </span>
          <input  v-if="element.inputType == 'Range'"  type="range" disabled  v-model="element.attributeName">
          <!-- Radio -->
          <div class="check-wrap" v-if=" element.inputType == 'RadioButton' && !element.radioSingle" disabled >
            <div class="envelop" style="width: 100%;" v-for="(check, zindex) in element.valueOptions" :key="zindex">                                                                                                                                                                                                                                                  
              <input type="radio" class="check input" :value="check" v-model="element.attributeName"/>
              <span class="check text" > {{check}} </span>
            </div>
          </div>
          <div class="check-wrap" v-if=" element.inputType == 'RadioButton' && element.radioSingle == true" disabled>
            <div class="envelop"  style="width: 100%;" v-for="(check, zindex) in element.valueOptions" :key="zindex">
              <input type="radio" v-on:click="f_radioSingle(element.itemModel, index)" class="check input" :value="check" v-model="element.attributeName"/> 
              <span class="check text" > {{check}} </span>
            </div>
          </div>
          <div class="check-wrap" v-if=" element.inputType == 'Boolean'" disabled>
            <div class="envelop" style="width: 100%;" >
              <input type="radio" :value="true"  v-on:click="f_radioSingle(element.itemModel, index)" class="check input" v-model="element.attributeName"/> 
              <span class="check text" > {{element.label}} </span>
            </div>
          </div>
          <!-- TEXT AREA -->
          <textarea :style="element.styleInput" disabled v-if="element.inputType == 'TextArea'" v-model="element.attributeName" type="text" rows="5"></textarea>
          <!-- HTML -->
          <textarea :style="element.styleInput" disabled v-if="element.inputType == 'HTML'" v-model="element.attributeName" type="text" cols="" rows="5"></textarea>
          <!-- File -->
          <div class="element-wrap" v-if="element.inputType == 'File'">
            <input type="file" name="" disabled >
            <table class="table" id="myTable">  
              <thead>
                <slot name="columns">
                  <th> No </th>
                  <th> Item </th>
                  <th> Options </th>
                </slot>
              </thead>
              <tbody>
                <tr >
                  <td>No.</td>
                  <td > IMG </td>
                  <td class="options-td" style="padding: 0px !important;">
                    <div class="options">
                      <a > 
                        <div class="col edit">
                          <svg width="136" height="100" viewBox="0 0 136 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M135.73 48.4545C130.398 34.6257 121.141 22.6664 109.104 14.0549C97.0668 5.44343 82.7767 0.55715 68 0C53.2233 0.55715 38.9332 5.44343 26.896 14.0549C14.8588 22.6664 5.60216 34.6257 0.270079 48.4545C-0.0900263 49.4532 -0.0900263 50.5468 0.270079 51.5455C5.60216 65.3743 14.8588 77.3336 26.896 85.9451C38.9332 94.5566 53.2233 99.4428 68 100C82.7767 99.4428 97.0668 94.5566 109.104 85.9451C121.141 77.3336 130.398 65.3743 135.73 51.5455C136.09 50.5468 136.09 49.4532 135.73 48.4545V48.4545ZM68 79.5455C62.1719 79.5455 56.4746 77.8126 51.6287 74.5661C46.7828 71.3197 43.0059 66.7053 40.7756 61.3066C38.5453 55.9078 37.9617 49.9672 39.0987 44.236C40.2357 38.5047 43.0422 33.2402 47.1633 29.1082C51.2844 24.9762 56.535 22.1623 62.2512 21.0223C67.9673 19.8822 73.8922 20.4673 79.2767 22.7036C84.6612 24.9398 89.2634 28.7267 92.5013 33.5854C95.7393 38.4442 97.4675 44.1565 97.4675 50C97.4555 57.8322 94.3471 65.3403 88.8234 70.8785C83.2998 76.4168 75.8116 79.5334 68 79.5455V79.5455Z" fill="white" />
                          </svg> 
                        </div>
                      </a>
                      <a >
                        <div class="col send" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2744 5.82925C14.5573 5.50596 14.3277 5 13.8981 5H11.5C11.2239 5 11 4.77614 11 4.5V0.5C11 0.223858 10.7761 0 10.5 0H5.5C5.22386 0 5 0.223858 5 0.5V4.5C5 4.77614 4.77614 5 4.5 5H2.10188C1.6723 5 1.44272 5.50596 1.7256 5.82925L7.62371 12.57C7.82292 12.7976 8.17708 12.7976 8.37629 12.57L14.2744 5.82925ZM0 14.5C0 14.2239 0.223858 14 0.5 14H15.5C15.7761 14 16 14.2239 16 14.5V15.5C16 15.7761 15.7761 16 15.5 16H0.5C0.223858 16 0 15.7761 0 15.5V14.5Z" fill="white"/>
                          </svg>
                        </div>
                      </a>
                      <a>
                        <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                          <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                            <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="element.inputType == 'Computed'">
            <input type="text" v-if="element.formula" v-model="element.formula" />
            <span v-else >No formula found</span>
          </div>
          <div v-if="element.inputType == 'structureMaker'" style="width:100%; background:#ccc; padding: 20px;">
            <p style="text-align:center; font-size: 14px; color:#000; ">Structure Maker: {{element.attributeName}}</p>
          </div>
        </div>
        <!-- Meta Layout -->
        <div v-if="element.metaType == 'metaLayout' " >
          <paper-constructor :palette="palette" :idioms="idioms" :listDrop="element.smartComponents" v-if=" element.isRepeatable == false " :styleDragg="setStyle(element, 'component')" :language="language"/>
          <p style="color:red;" v-if="element.isRepeatable == null"> Not Found "element.isRepeatable" {{element.isRepeatable}} </p>
        </div>
        <!-- Meta Element  -->
        <div v-if="element.metaType == 'metaElement'">
          
          <!--Div-->
          <paper-constructor :palette="palette" :idioms="idioms" v-if="element.elementType == 'Div' || element.elementType == 'menu' || element.elementType == 'contactForm'" :listDrop="element.smartComponents" :language="language" :styleDragg="setStyle(element, 'component')"  :mode="mode" :colors="colors" :edit="edit"/>
          <!--DropDown-->
          <div v-if="element.elementType == 'dropdhown'" class="dropdown">
            <p
              :id="element.id" 
              :style="setStyle(element, 'component')"  
              class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ element.text[language]}}
            </p>
            <paper-constructor  class="dropdown-menu show" :aria-labelledby="element.id" :id="'dropdown-'+element.id"  :palette="palette" :language="language" :idioms="idioms" :listDrop="element.smartComponents" :styleDragg="setStyle(element, 'component')"  :mode="mode" :colors="colors" :edit="edit"/>

          </div>
          <div class="dropdown" v-if="element.elementType == 'dropdown'">
            <p v-on:mouseover="dropDownAction('#drop'+element.id)" :style="setStyle(element, 'component')"   class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
              {{ element.text[language]}}
            </p>
            <div class="dropdown-menu" :id="'drop'+element.id">
              <paper-constructor   :palette="palette" :language="language" :idioms="idioms" :listDrop="element.smartComponents" :styleDragg="setStyle(element, 'component')"  :mode="mode" :colors="colors" :edit="edit"/>
            </div>
          </div>
          <!--Title-->
          <h1 v-if="element.elementType == 'Title' && element.titleType == 'h1'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h1>
          <h2 v-if="element.elementType == 'Title' && element.titleType == 'h2'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h2>
          <h3 v-if="element.elementType == 'Title' && element.titleType == 'h3'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h3>
          <h4 v-if="element.elementType == 'Title' && element.titleType == 'h4'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h4>
          <h5 v-if="element.elementType == 'Title' && element.titleType == 'h5'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h5>
          <h6 v-if="element.elementType == 'Title' && element.titleType == 'h6'" :style="setStyle(element, 'component')"> {{element.text[language]}}</h6>
          <!--language-->
          <div v-if="element.elementType == 'language'"> 
            <span v-for="(idiom, yindex) in idioms" :key="yindex" :style="setStyle(element, 'component')">{{idiom.toUpperCase()}}</span>
          </div>
          <!--Img-->
          <img  v-if="element.elementType.toLowerCase() == 'img'" :src="element.url" alt="" :style="setStyle(element, 'component')">
          <!--Video-->
          <video v-if="element.elementType.toLowerCase() == 'video'" class="videohome" :style="setStyle(element, 'component')" :playsinline="element.playsinline" :autoplay="element.autoplay" :muted="element.muted" :loop="element.loop" :poster="element.poster" >
            <source :src="element.url" type="video/webm">
            <source :src="element.url" type="video/mp4">
          </video>
          <!--iframe-->
          <iframe v-if="element.elementType == 'iframe'" :src="element.url" frameborder="0" :style="setStyle(element, 'component')"></iframe>
          <!--Button-->
          <ui-button v-if="element.elementType == 'smartButton' || element.elementType == 'shopButton' " :color="'gray'" :text="element.text[language]+' ('+element.buttonType+')'" :position="'center'" :style="setStyle(element, 'component')"/>

          <!--Button-->
          <ui-button v-if="element.elementType == 'smart-button'" :color="'gray'" :text="element.text[language]" :position="'center'" :style="setStyle(element, 'component')"/>

          <!--Text-->
          <p style="overflow:auto" v-if="element.elementType == 'Text' && element.class != 'dateFormat'" :style="setStyle(element, 'component')">{{ element.text[language]}}</p>
          <p style="overflow:auto" v-if="element.elementType == 'Text' && element.class == 'dateFormat'" :style="setStyle(element, 'component')">date {{ element.text[language]}}</p>
          <!--Collapse-->
          <h5 v-if="element.elementType == 'Collapse'" data-toggle="collapse" :href="'#collapse-'+element.id+'a'" role="button" aria-expanded="false" aria-controls="collapseExample" :style="setStyle(element, 'component')">
            {{element.text[language]}}
          </h5>
          <paper-constructor :palette="palette" :language="language" :idioms="idioms" v-if="element.elementType == 'link'" :listDrop="element.smartComponents" :styleDragg="setStyle(element, 'component')"  :mode="mode" :colors="colors" :edit="edit"/>
          <div v-if="element.elementType == 'Collapse'" class="collapse show" :id="'collapse-'+element.id+'a'">
            <paper-constructor :palette="palette" :language="language" :idioms="idioms" :listDrop="element.smartComponents"/>
          </div>
          <!--Charts-->
          <ui-charts v-if="element.elementType == 'Charts'" :type="element.typeCharts" :component="element" />
          <!--shape-->
          <div class="shape" v-if="element.elementType == 'shape'">
            <svg class="rectangle" v-if="element.shapeType == 'rectangle'|| element.shapeType == undefined" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="100" height="100" fill="white"/>
            </svg>
            <svg class="line" v-if="element.shapeType == 'line'" width="100" height="2" viewBox="0 0 100 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="1" x2="100" y2="1" stroke="white" stroke-width="2"/>
            </svg>
            <svg class="ellipse" v-if="element.shapeType == 'ellipse'" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" fill="white"/>
            </svg>
            <svg class="poligon" v-if="element.shapeType == 'poligon'" width="86" height="75" viewBox="0 0 86 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M43 0L86 75H0L43 0Z" fill="#C4C4C4"/>
            </svg>
            <div class="text">
              
            </div>
          </div>
        </div>
        <h6 v-if="element.metaType == 'metaModule' || element.metaType == 'customModule'" :style="setStyle(element, 'component')" >{{element.text[language]}}</h6>  
        <!-- Table Repeatable -->
        <div class="element-wrap " v-if="element.isRepeatable == true">
          <table class="table table-repeatable" id="myTable" >  
            <thead>
              <th> No </th>
              <th v-for="(item, index) in element.smartComponents" :key="index"> {{item.label}} </th> 
              <th> Options </th>
            </thead>
            <tbody>
              <tr>
                <td>No...</td>
                <td v-for="(item, index) in element.smartComponents" :key="index">{{item.attributeName}}</td>
                <td class="options-td" style="padding: 0px !important; ">
                  <div class="options" style="width: auto;!important">
                    <a style="cursor: pointer" > 
                      <div class="col edit" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                        <svg width="105" height="100" viewBox="0 0 105 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.78445 100C4.98509 99.9986 4.19477 99.8389 3.46373 99.5308C2.7327 99.2228 2.07695 98.7732 1.53818 98.2106C0.989677 97.6529 0.571128 96.991 0.309987 96.2683C0.0488455 95.5456 -0.0489889 94.7785 0.022897 94.0173L1.43448 79.2308L66.6437 17.1318L87.0223 36.5398L21.8304 98.6333L6.30875 99.978C6.13445 99.9933 5.95948 100.001 5.78445 100V100ZM91.09 32.6593L70.7171 13.2513L82.9374 1.60976C83.4725 1.09944 84.1079 0.6946 84.8073 0.418386C85.5068 0.142172 86.2565 0 87.0137 0C87.7708 0 88.5205 0.142172 89.22 0.418386C89.9194 0.6946 90.5549 1.09944 91.09 1.60976L103.31 13.2513C103.846 13.761 104.271 14.3664 104.561 15.0327C104.851 15.699 105 16.4132 105 17.1345C105 17.8558 104.851 18.57 104.561 19.2363C104.271 19.9027 103.846 20.508 103.31 21.0178L91.0957 32.6538L91.09 32.6593V32.6593Z" fill="white" />
                        </svg>
                      </div>
                    </a>
                    <a style="cursor: pointer" >
                      <div class="col delete" style="width: 31px; height: 31px; padding: 7px 8px!important; ">
                        <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
                          <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
                        </svg>
                      </div>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
          </table>
        </div>
        <!-- Button Repeatable -->
        <div class="btn btn-repeatable"  v-if="element.isRepeatable == true" >
          <i class='bx bx-plus' ></i>
        </div>
        <div v-if="mode == 'maker' && edit == true" class="btn btn-custom" data-toggle="modal" :data-target="'#modal-custom'" v-on:click="setCustomItem(element)"  > 
          <i class='bx bx-dots-vertical-rounded'></i>
        </div>
        <div v-if="mode == 'post' && edit == true"  class="btn btn-custom" data-toggle="modal" :data-target="'#modal-post-'+element.id" v-on:click="setCustomPost(element)" > 
          <i class='bx bx-dots-vertical-rounded'></i>
        </div>

      </div>
    </template>
  </draggable>
  <!--Modal custom0-->
  
</template>
<script>
//AWS - GRAPHQL
import { smd_listEntity } from '../../graphql/queries'
//Tools
import { API } from "aws-amplify";
import Vuex from 'vuex'
//Componentsz
import draggable from "vuedraggable";
import PaperDisplayer from './PaperDisplayer.vue';
import UiButton from '../ui/UiButton.vue'
import UiCharts from '../ui/UiCharts.vue'
import { processStyle } from '../../store/tools.js';

export default {
  name:'paper-constructor',
  components:{
    draggable,
    PaperDisplayer,
    UiButton,
    UiCharts,
  },
  props:{
    listDrop:{
      type: Array,  
      default: () => []
    },
    data:{
      type: Array,  
      default: () => []
    },
    styleDragg:{
      type: String,  
      default: 'padding:20px;'
    },
    frame:{
      type: String,  
      default: ''
    },
    colors:{
      type: Array,  
      default: ['#404040','#595959','#737373','#8C8C8C','#BFBFBF']
    },
    mode:{
      type: String,  
      default: 'maker'
    },
    editable:{
      type: Boolean,  
      default: false
    },
    edit:{
      type: Boolean,  
      default: true
    },
    idioms:{
      type: Array,  
      default: ['en']
    },
    language:{
      type: String,  
      default:'en'
    },
    palette:{
      type: Array,  
      default: ['#F2F2F2','#BFBFBF','#8C8C8C','#262626','#0D0D0D']
    },
  },
  data() {
    return {
      entities: [],
      forms: [],
      searchSelect:'',
      ancla:0
    }
  },
  created() {
  },
  methods: {
    ...Vuex.mapMutations(['setCustomItem']),
    setTypeInput(data,att){
      let type = 'string'
      try {
        type =  typeof JSON.parse(data)
      } catch (error) {
        type = typeof data
      }
      let value = 'text'
      if (att.startsWith('_')) {
        switch (type) {
          case 'object':
            value = 'text'
          break;
          case 'boolean':
            value = 'radio'
          break;
        }
        return value
      }
    },
    setStylePost(att, index ){
      console.log(att);
      let action = att
      if (att.includes('component')) {
        action = 'component'
      }
      if (att.includes('wrap')) {
        action = 'wrap'
      }
      console.log(action);
      
      function replaceStyleElement(p_string, p_propertyName, p_newValue) {
        let w_tmp = p_string.split(";");
        let w_res = [];
        let exist = false
        for (var prop of w_tmp) {
          let a_prop = prop.split(":");
          //console.log("aprop en func",a_prop);
          if (a_prop[0].trim() == p_propertyName) {
            a_prop[1] = p_newValue;
            exist = true
            //console.log("encontro la propiedadd clor dentro de style");
          } 
          //prop = a_prop.toString();
          w_res.push(a_prop.join(":"));
        }
        //console.log("reemplazando ",w_res);
        w_res = w_res.join(";")
        if (exist == false) {
          w_res = w_res + p_propertyName + ':'+p_newValue +';'
        }
        console.log(w_res);
        return w_res
      }
      switch (action) {
        case 'color':
          this.listDrop[index].styleComponent = replaceStyleElement(this.listDrop[index].styleComponent, 'color', this.listDrop[index]['_color'])
        break;
        case 'background':
          this.listDrop[index].styleComponent = this.listDrop[index].styleComponent + 'background:'+this.listDrop[index].color +';'
        break;
        case 'wrap':
          this.listDrop[index].styleWrap = replaceStyleElement(this.listDrop[index].styleWrap, att.replace('_wrap_',''), this.listDrop[index][att]) 
        break;
        case 'component':
          this.listDrop[index].styleComponent = replaceStyleElement(this.listDrop[index].styleWrap, att.replace('_component_',''), this.listDrop[index][att]) 
        break;
      }
      this.$forceUpdate();
      console.log('----------------------------------');
      console.log(this.listDrop[index]);
    },
    //Contructor Funcionalitys
    setCustomPost(component){
      let index = 0
      for (let zindex = 0; zindex < this.listDrop.length; zindex++) {
        let element = this.listDrop[zindex];
        if (component.id == element.id) {
          console.log('ENCONTRADO',element );
          index = zindex
        }
      }
      this.listDrop[index].top = 0
      this.listDrop[index].right = 0
      this.listDrop[index].bottom = 0
      this.listDrop[index].left = 0
      for (let vindex = 0; vindex < component.styleWrap.split(';').length-1; vindex++) {
        try {
          let element = component.styleWrap.split(';')[vindex].split(':') ;
          this.listDrop[index]['_wrap_'+element[0].trim()] = element[1].trim()
        } catch (error) {
          console.log(error);
        }
      }
      for (let vindex = 0; vindex < component.styleComponent.split(';').length-1; vindex++) {
        try {
          let element = component.styleComponent.split(';')[vindex].split(':') ;
          this.listDrop[index]['_component_'+element[0].trim()] = element[1].trim()
        } catch (error) {
          console.log(error);
        }
      }
      this.$forceUpdate();
    },
    setEditor(component ){
      try {
        setTimeout(() => {
          //$(`#editor-${component.id}`).trumbowyg();
          document.getElementById(`editor-${component.id}`).innerHTML = unescape(component.help)
        }, 100);
      } catch (error) {
        
      }
    },
    setDataEditor(component, index, att){
      this.listDrop[index][att] = escape(document.getElementById(`editor-${component.id}`).innerHTML)
    },
    f_radioSingle( value, index, action){
      if (value == true) {
        this.listDrop[index][action] = false
      }else{
        this.listDrop[index][action] = true
      }
    },
    setStyle(component, type){
      try {
        //if (!component.style.font.font || component.style.font.font == '' || component.style.font.font== 'Text' || component.style.font.font== 'Montserrat' ) {
          //component.style.font.font = "'Montserrat'"
        //}
        //console.log( component.style.font.font);
        let style = ''
        let responsive = 0
        let font = ''
        if (window.screen.width < 992) {
          responsive = 1
        } else if(window.screen.width < 767){
          responsive = 2
        } else if(window.screen.width < 520){
          responsive = 3
        }
        if (typeof component.style.font.font == 'string') {
          switch (component.style.font.font) {
            case 'Title': component.style.font.font = 0; break;
            case 'SubTitle': component.style.font.font = 1; break;
            case 'Text': component.style.font.font = 2; break;
            case 'SubText': component.style.font.font = 3; break;
          }
        }

        style = processStyle( component , type , this.webSite , responsive, true )
        let palette = this.palette
        let regexp = /\[.+?\]/g;
        let bind = [...style.matchAll(regexp)];
  
        for (let index = 0; index < bind.length; index++) {
          let parameter = JSON.parse(bind[index][0])
          style = style.replace(bind[index][0], palette[parameter[1]])
        }
        return style
        
      } catch (error) {
        console.log(error);
      }
    },
    setStyleWrap(element){
      let style = element.styleWrap

      let palette = this.palette
      let regexp = /\[.+?\]/g;
      let bind = [...style.matchAll(regexp)];

      for (let index = 0; index < bind.length; index++) {
        let parameter = JSON.parse(bind[index][0])
        style = style.replace(bind[index][0], palette[parameter[1]])
      }
      //console.log(style);
      return style
    },
    setStyleComponent(element){
      let style = element.styleComponent
      console.log(style);
      try {
        let palette = this.palette
        let regexp = /\[.+?\]/g;
        let bind = [...style.matchAll(regexp)];
        
        for (let index = 0; index < bind.length; index++) {
          let parameter = JSON.parse(bind[index][0])
          style = style.replace(bind[index][0], palette[parameter[1]])
        }
        
      } catch (error) {
        console.log(error);
      }
      return style
    },

    log: function (evt) {
      console.log(evt);
    },
    trash(index, attributeName){
      this.listDrop.splice(index,1 )
    },
    setString(att, index){
      setTimeout(() => {        
        if (this.listDrop[index].graphQl != undefined) {
          console.log('--');
          if (typeof this.listDrop[index].graphQl == 'object') {
            console.log(typeof this.listDrop[index].graphQl);
            this.listDrop[index].graphQl = JSON.stringify(this.listDrop[index].graphQl)
          }
        }
      }, 100);
    },
    f_searchSelect(vindex){
      let list = document.getElementById("dropdown"+vindex);
      for (let i = 1; i < list.children.length; i++) {
        let txtValue = list.children[i].textContent || list.children[i].innerText;
        if (txtValue.toLowerCase().indexOf(this.searchSelect.toLowerCase()) > -1) { 
          document.getElementById("dropdown"+vindex).children[i].style.display = "";
        } else {
          document.getElementById("dropdown"+vindex).children[i].style.display = "none";
        }
      }
    },
    setValueSelect(index, att, value){
      console.log(index);
      console.log(this.listDrop[index]);
      console.log(att);
      console.log(value);
      this.listDrop[index][att] = value
    },
    unSetValueSelect(vindex, value, att){
      /*if (this.listDrop[vindex][att] == value) {
        return 'cursor:pointer; background: #0057ff; color:#fff;'
      }else{
        return 'cursor:pointer;'
      }*/
    },
    validationValue(index, att){
      console.log(this.listDrop[index]);
      console.log(att);
      try {        
        let value = document.getElementById('input-'+this.listDrop[index].id).value
        switch (att) {
          case 'metaJSON':
              this.listDrop[index].metaJSON = JSON.parse(value)
          break;
          case 'actionJSON':
              this.listDrop[index].actionJSON = JSON.parse(value)
          break;
          case 'valueOptions':
              this.listDrop[index].valueOptions = JSON.parse(value)
          break;
        }
        document.getElementById('input-'+this.listDrop[index].id).style = ''
      } catch (error) {
        console.log(error);
        document.getElementById('input-'+this.listDrop[index].id).style = 'background: #ff3030; color: #fff;'
      }
    },
    //Components
    dropDownAction(id){
      console.log(id);
      //$(id).dropdown('toggle')
      let elementId = id.replace('#','')
      console.log(document.getElementById(elementId).classList.contains('show'));
      if(document.getElementById(elementId).classList.contains('show')){
        document.getElementById(elementId).classList.remove('show')
      }else{
        document.getElementById(elementId).classList.add('show')
      }
    },
  },
  computed:{
    ...Vuex.mapState(["organizationID", "user", "permissions", "classes","webSite",'srcFonts']),

    dragOptions() {
      return {
        animation: 200,
        group: "components",  
        disabled: false,
        ghostClass: "ghost",
      };
    },
  }
}
</script>